import React from 'react'
import styles from './labels.module.scss'

interface Props {
  labels: string[]
}
export const LabelsList = ({ labels }: Props) => {
  if (!labels.length) {
    return null
  }
  return (
    <ul className={styles.labels}>
      {labels.map((label) => (
        <li key={`label-${label}`} className={styles.labels__item}>
          {label.toUpperCase()}
        </li>
      ))}
    </ul>
  )
}
