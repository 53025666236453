import React from 'react'
import styles from './characteristics.module.scss'
import { textWithLocale } from '../../../i18n'
import { ModeloInfo } from '../types'

interface Props {
  modelo: ModeloInfo
  withTitle?: boolean
}
export const CharacteristicsList: React.FC<Props> = ({ modelo }) => (
  <React.Fragment>
    <ul className={styles.characteristics__list}>
      {modelo[textWithLocale('caracteristicas1')] && (
        <li className={styles.characteristics__list_item}>
          {modelo[textWithLocale('caracteristicas1')]}
        </li>
      )}
      {modelo[textWithLocale('caracteristicas2')] && (
        <li className={styles.characteristics__list_item}>
          {modelo[textWithLocale('caracteristicas2')]}
        </li>
      )}
      {modelo[textWithLocale('caracteristicas3')] && (
        <li className={styles.characteristics__list_item}>
          {modelo[textWithLocale('caracteristicas3')]}
        </li>
      )}
      {modelo[textWithLocale('caracteristicas4')] && (
        <li className={styles.characteristics__list_item}>
          {modelo[textWithLocale('caracteristicas4')]}
        </li>
      )}
    </ul>
  </React.Fragment>
)
