import React, { useState } from 'react'
import useForm from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import Done from '../Done/Done'
import { t } from '../../i18n'
import logic from '../../logic'
import route from '../../utils/route'
import { alertProp } from '../../utils/swal'
import Button from '../buttons/Button'
import CheckBoxYellowCesta from '../checkboxYellowCesta/CheckBoxYellowCesta'
import FormError from '../FormError/FormError'
import InputCesta from '../inputCesta/InputCesta'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './index.module.scss'

const parseSpecText = (tyreSpecs) => {
  const inputString = `${tyreSpecs.ancho}${
    tyreSpecs.alto ? `/${tyreSpecs.alto}` : ''
  } ${tyreSpecs.radial || ''} ${tyreSpecs.llanta || ''} ${
    tyreSpecs.serie || ''
  } ${tyreSpecs.normativa || ''} - ${tyreSpecs.aplicacion || ''}`
  return inputString.trim().replace(/\s+/g, ' ')
}
export interface RequestTyreMotoData {
  marca: string
  modelo: string
  id_site_modelo_neumatico_moto: number
  id_site_producto: number
  tyreSpecs: {
    ancho: string
    alto: string
    llanta: string
    serie: string
    aplicacion: string
    normativa: string
    radial: string
  }
}
interface EmailData {
  email: string
  checkboxPrivacy: boolean
}

const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .email(t('validations.register.valid_email'))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  checkboxPrivacy: yup
    .boolean()
    .oneOf([true], t('validations.register.accept_terms')),
})

interface InSiteMarcaNeumatico {
  clasificacion: 'BUDGET' | 'QUALITY' | 'PREMIUM'
  logo_marca: string
  marca: string
}

interface InSiteModeloNeumatico {
  id_site_modelo_neumatico_moto: number
  modelo: string
  site_marca_neumatico: InSiteMarcaNeumatico
}

interface InSiteProducto {
  activo_ep: number
  activo_rodi: number
  disponibilidad: number
  id_navision: string
  id_site_producto: number
}

export interface InSelectedNeumaticoMoto {
  ancho: string
  alto: string
  llanta: string
  serie: string
  aplicacion: string
  normativa: string
  radial: string
  id_site_productos_neumaticos_moto: number
  site_modelo_neumatico: InSiteModeloNeumatico
  site_producto: InSiteProducto
}

interface Props {
  selectedNeumatico: RequestTyreMotoData
  successMessage: boolean
  setSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
}
export default function RequestTyreMoto({
  selectedNeumatico,
  successMessage,
  setSuccessMessage,
}: Props) {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    triggerValidation,
  } = useForm<EmailData>({
    mode: 'onChange',
    validationSchema: EmailSchema,
  })
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    const { email } = data
    setLoading(true)
    return logic
      .requestNeumaticoMotoAvailability(
        email,
        selectedNeumatico.marca,
        selectedNeumatico.modelo,
        selectedNeumatico.id_site_modelo_neumatico_moto,
        selectedNeumatico.id_site_producto,
        selectedNeumatico.tyreSpecs
      )
      .then((res) => {
        if (res.errors) {
          res.errors.forEach((e) => {
            setError(e.field, e.type, t(e.message))
          })
          setLoading(false)
          return
        }
        setLoading(false)
        setSuccessMessage(true)
      })
      .catch((err) => {
        setLoading(false)
        Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: err.message,
          })
        )
      })
  }

  return (
    <section>
      {loading && <SpinnerWithText text={t('alta_vehiculo.cargando')} />}
      {!successMessage && (
        <div className={loading ? styles.hidden : styles.email_input_container}>
          {selectedNeumatico && (
            <div className={styles.request_email_message}>
              <p>
                {t(
                  'landing_neumaticos-moto.neumatico_item.pedir_info_mensaje',
                  {
                    marca: selectedNeumatico.marca,
                    modelo: selectedNeumatico.modelo,
                    specsText: parseSpecText(selectedNeumatico.tyreSpecs),
                  }
                )}
              </p>
              {t(
                'landing_neumaticos.neumatico_item.pedir_info_mensaje_contacto'
              )}
            </div>
          )}

          <form
            onChange={(e) => {
              const target = e.target as HTMLInputElement
              // Revalidar cualquier campo cuando cambia su valor
              clearError(target.name as keyof EmailData)
              triggerValidation({
                name: target.name as keyof EmailData,
              })
            }}
            className={styles.request_neumatico_form}
            onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.input_wrapper}>
              <InputCesta
                inputSize="big"
                label={
                  <div className={styles.label_email}>{t('login.email')}</div>
                }
                name="email"
                refe={register}
                errors={errors}
                type="text"
              />
            </div>
            <CheckBoxYellowCesta
              label={
                <div className={styles.label_privacy}>
                  <span>{t('registerForm.checkbox-privacy-read')}</span>
                  <a
                    className={styles.label_privacy_link}
                    href={route('politica-privacidad.index')}
                    target="_blank"
                    rel="noreferrer">
                    {t('registerForm.checkbox-privacy-policy')}
                  </a>
                </div>
              }
              name="checkboxPrivacy"
              refe={register}
            />
            <FormError errors={errors} field="checkboxPrivacy" />
            <Button buttonClass="rodi_button" type="submit">
              {t('registerForm.enviar')}
            </Button>
          </form>
        </div>
      )}
      {successMessage && (
        <div className={styles.send_email_success}>
          <Done />
          <div className={styles.email_success_message}>
            {t('landing_neumaticos.solicitar')}
          </div>
        </div>
      )}
    </section>
  )
}
