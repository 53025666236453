import React from 'react'
import { t } from '../../../i18n'
import Button from '../../buttons/Button'
import { RequestTyreMotoData } from '../../RequestTyre/RequestTyreMoto'
import {
  ModeloMotoInfo,
  NeumaticoMotoInfo,
  NeumaticoMotoProducto,
} from '../types'

interface Props {
  modelo: ModeloMotoInfo
  neumatico: NeumaticoMotoInfo & NeumaticoMotoProducto
  selectNeumatico: (selected: RequestTyreMotoData) => void
  openModal: (open: boolean) => void
}
const RequestMotoTyreCTA: React.FC<Props> = ({
  modelo,
  neumatico,
  selectNeumatico,
  openModal,
}) => {
  const requestedNeumatico: RequestTyreMotoData = {
    marca: modelo.marca.nombre,
    modelo: modelo.modelo,
    id_site_modelo_neumatico_moto: modelo.id_site_modelo_neumatico_moto,
    id_site_producto: neumatico.id_site_productos_neumaticos_moto,
    tyreSpecs: {
      ancho: neumatico.ancho,
      alto: neumatico.alto,
      llanta: neumatico.llanta,
      serie: neumatico.serie,
      aplicacion: neumatico.aplicacion,
      normativa: neumatico.normativa,
      radial: neumatico.radial,
    },
  }

  const onClickRequest = () => {
    selectNeumatico(requestedNeumatico)
    openModal(true)
  }
  return (
    <>
      <Button
        disclaimerMessage={t('landing_neumaticos.mensaje_no_disponible')}
        buttonClass={'rodi_button_no_color'}
        onClick={onClickRequest}>
        {t('landing_neumaticos.ver_disponibilidad')}
      </Button>
    </>
  )
}

export default RequestMotoTyreCTA
