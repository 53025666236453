import { graphql, PageProps } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import { makeNeumaticosSchema } from '../../utils/schema'
import {
  getModeloImagenes,
  getModeloMarca,
  getNeumaticoMotoSpecs,
  neumaticoActivo,
  priceWithTax,
} from '../../components/FichasNeumaticos/utils'
import styles from './fichas.module.scss'
import NeumaticoMotoCard from '../../components/FichasNeumaticos/Cards/NeumaticoMotoCard'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import ComprarNeumaticoCTA from '../../components/FichasNeumaticos/Ctas/ComprarNeumaticoCTA'
import RequestMotoTyreCTA from '../../components/FichasNeumaticos/Ctas/RequestMotoTyreCTA'
import StandardModal from '../../components/modal/standardModal/StandardModal'
import RequestTyreMoto, {
  RequestTyreMotoData,
} from '../../components/RequestTyre/RequestTyreMoto'
import { intl } from '../../../../sites/rodi/i18n'

export default function FichaNeumaticosMotoPage({
  data: {
    modeloInfo,
    neumaticoInfo,
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_es_mobile,
    banners_ca_mobile,
  },
}: PageProps<Queries.FichaNeumaticosMotoPageQuery>) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
    slug,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
  }

  const { fichas } = getModeloImagenes(imagenes)

  const labels = [
    neumaticoInfo.gama,
    neumaticoInfo.tipo,
    neumaticoInfo.aplicacion,
  ]

  const [showModal, setShowModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreMotoData | null
  )
  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const isActive = neumaticoActivo(neumaticoInfo.producto)

  const price = isActive
    ? intl.formatNumber(priceWithTax(neumaticoInfo.producto), {
        style: 'currency',
        currency: 'EUR',
      })
    : undefined

  const CTAButton = isActive ? (
    <ComprarNeumaticoCTA
      neumaticoInfo={neumaticoInfo}
      marcaInfo={modeloInfo.marca}
    />
  ) : (
    <RequestMotoTyreCTA
      modelo={modeloInfo}
      neumatico={neumaticoInfo}
      openModal={setShowModal}
      selectNeumatico={setSelectedNeumatico}></RequestMotoTyreCTA>
  )

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos.marca', {
            marca: marca.toLowerCase(),
          }),
        },
        {
          display: modelo,
          link: route('neumaticos-moto.modelo', {
            marca: marca.toLowerCase(),
            modelo: slug,
          }),
        },
        {
          display: getNeumaticoMotoSpecs(neumaticoInfo),
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: `${getModeloMarca(modeloInfo)} ${getNeumaticoMotoSpecs(
                neumaticoInfo
              )}`,
              image: fichas.length && fichas[0].url,
              description: modelo[textWithLocale('recomendado_para')],
              brand: marca,
              price: priceWithTax(neumaticoInfo.producto).toString(),
            })
          ),
        }}
      />

      <section className={styles.ficha_neumatico__container}>
        <section className={styles.ficha_neumatico__content}>
          <NeumaticoMotoCard
            neumatico={neumaticoInfo}
            modelo={modeloInfo}
            banners={banners}
            cta={CTAButton}
            labels={labels}
            price={price}
          />
        </section>
      </section>
      <StandardModal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyreMoto
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
        />
      </StandardModal>
    </Layout>
  )
}
export const pageQuery = graphql`
  query FichaNeumaticosMotoPage(
    $idModelo: Int!
    $idSiteProductoNeumatico: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
  ) {
    modeloInfo: modelosMoto(id_site_modelo_neumatico_moto: { eq: $idModelo }) {
      ...modeloMotoInfo
      ...modeloMotoMarca
      ...modeloMotoImagen
    }
    neumaticoInfo: neumaticosMoto(
      id_site_productos_neumaticos_moto: { eq: $idSiteProductoNeumatico }
    ) {
      ...neumaticoMotoInfo
      ...productoMotoInfo
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
