import React from 'react'
import styles from './characteristics.module.scss'
import { t } from '../../../i18n'
import {
  ModeloMotoInfo,
  NeumaticoMotoInfo,
  NeumaticoMotoProducto,
} from '../types'
import { getNeumaticoMotoSpecs } from '../utils'

interface Props {
  modelo: ModeloMotoInfo
  neumatico?: NeumaticoMotoInfo & NeumaticoMotoProducto
}

const drawRow = (label, value) => {
  return (
    <>
      {value && value !== '' && (
        <>
          <dt>{label}</dt>
          <dd>{value.toUpperCase()}</dd>
        </>
      )}
    </>
  )
}

export const CharacteristicsMotoTable: React.FC<Props> = ({
  modelo,
  neumatico,
}) => (
  <dl className={styles.characteristics__table}>
    <dt>{t('landing_neumaticos.titulos_caracteristicas_tecnicas.marca')}</dt>
    <dd>{modelo.marca.nombre}</dd>

    <dt>{t('landing_neumaticos.titulos_caracteristicas_tecnicas.modelo')}</dt>
    <dd> {modelo.modelo}</dd>

    {neumatico ? (
      <React.Fragment>
        <dt>
          {t('landing_neumaticos.titulos_caracteristicas_tecnicas.medidas')}
        </dt>
        <dd>{getNeumaticoMotoSpecs(neumatico)}</dd>
        <dt>
          {t('landing_neumaticos.titulos_caracteristicas_tecnicas.aplicacion')}
        </dt>
        <dd>{neumatico.aplicacion.toUpperCase()}</dd>
        {drawRow(
          t('landing_neumaticos.titulos_caracteristicas_tecnicas.marcaje'),
          neumatico.marcaje
        )}
        {drawRow(
          t('landing_neumaticos.titulos_caracteristicas_tecnicas.flanco'),
          neumatico.flanco
        )}
        {drawRow(
          t('landing_neumaticos.titulos_caracteristicas_tecnicas.homologacion'),
          neumatico.homologacion
        )}
        {drawRow(
          t('landing_neumaticos.titulos_caracteristicas_tecnicas.compuesto'),
          neumatico.compuesto
        )}
        {drawRow(
          t('landing_neumaticos.titulos_caracteristicas_tecnicas.terreno'),
          neumatico.terreno
        )}
      </React.Fragment>
    ) : null}
  </dl>
)
