import React, { ReactNode } from 'react'
import { IAppState } from '../../../context/context'
import { intl, locale, t, textWithLocale } from '../../../i18n'
import ImageGallery from '../../imageGallery/ImageGallery'
import { PromotionBanner } from '../../promotionBanner/PromotionBanner'
import { connect } from 'react-redux'
import {
  getModeloImagenes,
  getModeloMarca,
  getNeumaticoMotoSpecs,
  priceWithTax,
} from '../utils'
import { selectPostCode } from '../../../context/selectors/userSelectors'
import { getImages, ImageNode } from '../../../utils/imagesS3'
import ResponsiveSliderBanner from '../../ResponsiveSliderBanner/ResponsiveSliderBanner'
import useUpdatePromocion from '../../../helpers/customHooks/useUpdatePromocion'
import { LabelsList } from '../Labels/LabelsList'
import { CharacteristicsList } from '../Characteristics/CharacteristicsList'
import { CharacteristicsMotoTable } from '../Characteristics/CharacteristicsMotoTable'
import styles from './card.module.scss'
import { CharacteristicsParagraphs } from '../Characteristics/CharacteristicsParagraphs'

interface NeumaticoMotoCardProps {
  postCode: string | null
  modelo: Queries.modeloMotoInfoFragment &
    Queries.modeloMotoMarcaFragment &
    Queries.modeloMotoImagenFragment
  neumatico?: Queries.productoMotoInfoFragment &
    Queries.neumaticoMotoInfoFragment
  banners: { [key: number]: ImageNode[] }
  cta: ReactNode
  labels?: string[]
  price?: string
}
const NeumaticoCard: React.FC<NeumaticoMotoCardProps> = ({
  postCode,
  modelo,
  neumatico,
  banners,
  cta: Cta,
  labels,
  price,
}) => {
  const updatedPromotion = useUpdatePromocion({
    id_site_marca_neumatico: modelo.id_site_marca_neumatico,
    id_site_modelo_neumatico: modelo.id_site_modelo_neumatico_moto,
    postCode,
    llanta: neumatico ? neumatico.llanta : undefined,
    categoria_producto: 'moto',
  })

  const bannerImagesDesktop = getImages(banners[`${locale}_desktop`], locale)
  const bannerImagesMobile = getImages(banners[`${locale}_mobile`], locale)

  const { fichas } = getModeloImagenes(modelo.imagenes)

  return (
    <div className={styles.neumatico_card__container}>
      <div className={styles.neumatico_card__left_sidebar}>
        <ImageGallery marca={modelo.marca.logo} fotos={fichas} tipo={'ficha'} />
      </div>

      <div className={styles.neumatico_card__content}>
        <div className={styles.neumatico_card__row}>
          <div
            className={`${styles.neumatico_card__full_column} ${styles.order_1}`}>
            <h1>
              <span>{getModeloMarca(modelo).toUpperCase()}</span>{' '}
              <span>{neumatico && getNeumaticoMotoSpecs(neumatico)}</span>
            </h1>
            {labels ? <LabelsList labels={labels} /> : null}
          </div>
          <div
            className={`${styles.neumatico_card__left_column} ${styles.order_5}`}>
            <CharacteristicsList modelo={modelo} />
          </div>
          <div
            className={`${styles.neumatico_card__right_column} ${styles.order_2}`}>
            {price ? (
              <div className={styles.neumatico_card__price}>{price}</div>
            ) : null}
            {Cta}
            {updatedPromotion.texto_promociones && (
              <div className={styles.neumatico_card__promobanner}>
                <PromotionBanner promocion={updatedPromotion} />
              </div>
            )}
          </div>
          {bannerImagesDesktop.length || bannerImagesMobile.length ? (
            <div
              className={`${styles.neumatico_card__full_column} ${styles.order_3}`}>
              <ResponsiveSliderBanner
                mobileImages={bannerImagesMobile}
                desktopImages={bannerImagesDesktop}
                sizesVariant={'banner_neumaticos'}
              />
            </div>
          ) : null}
          <div
            className={`${styles.neumatico_card__left_column} ${styles.order_4}`}>
            <h2>{t('landing_neumaticos.info.caracteristicas_tecnicas')}</h2>
            <CharacteristicsMotoTable neumatico={neumatico} modelo={modelo} />
          </div>
          <div
            className={`${styles.neumatico_card__left_column} ${styles.order_6}`}>
            {modelo[textWithLocale('recomendado_para')] && (
              <CharacteristicsParagraphs
                modelo={modelo}
                title={'landing_neumaticos.info.descripcion'}
                textKeys={['recomendado_para']}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps)(NeumaticoCard)
